import { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Flex,
  Heading,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ListItem,
  UnorderedList,
  Checkbox, CheckboxGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useDisclosure,
} from "@chakra-ui/react";

import { useNavigate } from 'react-router';

const PrivacyPolicy = ({ showToc, isTocModalOpen, userOptOut }) => {
  // const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialRef = useRef(null)

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkedItem, setCheckedItem] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const userSelected = (e) => {
    if (e == true) {
      setCheckedItem(true);
      setBtnDisabled(false);
    } else {
      setCheckedItem(false);
      setBtnDisabled(true);
    }
  }

  const navigate = useNavigate();

  const closeTocModal = () => {
    showToc(false)
  }

  return (
    <>
      <Modal isOpen={isTocModalOpen} onClose={onClose} scrollBehavior="inside" initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent mx="40px" rounded="md" minWidth="fit-content" height="fit-content"
          paddingBottom="1rem"
        >
          <ModalHeader
            bg="red.600"
            color="white"
            roundedTopLeft="md"
            roundedTopRight="md"
            textAlign="center"
            fontSize="25px"
            fontWeight='600'
          >
            Privacy Policy
          </ModalHeader>
          <ModalBody
            paddingX="5rem">
            <VStack alignItems="left" align="justify" justifyContent="space-between" className="p-10">
              <Box as="button" ref={initialRef} placeholder='' />
              {/* <Text color="black" as='i' align="center">Please note these Privacy Notice, below. Scroll down for acceptance acknowledgement.</Text><br /> */}
              <Heading color="black" as="b" align="center">CDL Health Scanner LLC</Heading>
              <Text color="black" as="b" align="center">Privacy Policy</Text>
              <Text color="black" as='i' align="center"><u>Last Updated: December 18, 2023</u></Text><br />
              <Box
                paddingBottom="10px"
                borderBottom="4px"
                borderColor="white"
              >
                <Text
                  textAlign="center"
                  fontWeight="800"
                  fontSize="20px"
                  paddingTop="16px"
                // textAlign="justify"
                >
                  NOTICE: You must be 18 years of age or older to use the CDL Health Scanner App. Use of the App
                  by minors under the age of 18 is strictly prohibited. We do not knowingly or intentionally
                  collect Personal Data from any person under the age of 18.
                </Text>
                <Flex
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="33px"
                  marginY="20px"
                  spacing={{ xxs: 2, xs: 4 }}
                  flexDirection="column"
                  gap="16px"
                  textAlign="justify"
                >
                  <Text
                  >
                    This Privacy Policy is applicable to the collection and use of Personal Information by CDL Health
                    Scanner, LLC (collectively, “we,” “us”, “our” or CDL). Your privacy is important to us, and we are
                    committed to protecting your privacy through our compliance with this Privacy Policy and our
                    legal obligations to you when you use the CDL Health Scanner App (“Scanner” or “App”).
                  </Text>
                  <Text

                  >
                    Herein we describe how we or our service providers or processors collect information about
                    you through our website, customer support service and the App once installed on your iOS or
                    Android device (mobile phone or tablet). We also explain how we use, disclose, store, process,
                    dispose and protect your information as the “controller” of that information once it is collected
                    by us.
                  </Text>
                  <Text >
                    Please read this Privacy Policy carefully. By using the website, customer support and/or the
                    App, you agree to the terms of this Privacy Policy. If you do not agree with its terms, do not use
                    the website, customer support or the App.
                  </Text>
                  <Text >
                    We may amend this Privacy Policy at any time to ensure compliance with applicable law or to
                    reflect changes in the way we collect, use, store, process, disclose, dispose or protect your
                    information. Changes will be accompanied by a change in the “Last Updated” date, as shown
                    above.
                  </Text>
                </Flex>
              </Box>

              <Box >
                <Text
                  fontSize="24px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  DEFINITIONS
                </Text>

                <Flex
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="33px"
                  marginY="20px"
                  flexDirection="column"
                  gap="16px"
                  textAlign="justify"
                >
                  <Text>
                    Capitalized words not defined here have the meaning ascribed to them in the Terms and
                    Conditions.
                  </Text>

                  <Text>
                    “Authorized Device” is the device You recorded as being the device from which you would use
                    the App.
                  </Text>
                  <Text >
                    “Authorized User” is an adult (over the age of 18) who has registered to use the App.
                  </Text>
                  <Text >
                    “Biometric Data” is a category of Personal Data that can be used to uniquely identify an
                    individual based on biological factors. Biometric data often refers to common biometric
                    measurements used for confirming your identity, such as fingerprints, voice prints, iris scans, and facial recognition. Biometric data also includes vein patterns unique to individuals, such as
                    those in the face, and heart rate and blood pressure measurements that are collected and used
                    by the App. Both Biometric Data and other data relating to heath are considered sensitive data
                    subject to strong security controls under privacy laws.
                  </Text>

                  <Text >
                    “Data Subject” means an adult (over the age of 18) who registers to use or uses the App or who
                    seeks assistance from CDL with respect to the App through customer support.
                  </Text>
                  <Text >
                    “Measurement Output” means the Personal and Biometric Data that is the result of processing
                    certain inputs regarding facial blood flow and facial features captured through the use of the
                    camera within the Authorized Device and certain Personal Data input by the User.
                  </Text>
                  <Text >
                    “Measurement Subject” means the adult (over the age of 18 years) who uses the App to take
                    biometric measurements of him/her/their-self and generate results from the App.
                  </Text>
                  <Text >
                    “Personal Data” or “Personal Information” means any information which could, on its own or in
                    combination with other information, be used to identify you.
                  </Text>
                  <Text >
                    “Registrant” means the adult (over the age of 18) who registers with CDL to establish an
                    account to use the App.
                  </Text>
                  <Text >
                    “Sensitive Data” includes health information. Biometric Data and demographic information
                    (such as your sex and age) that is collected and processed in the App. It is a subset of Personal
                    Data. This information is used to provide you with the service offered through the App,
                    including ensuring that the output of the processing is personalized to you. Sensitive Data is
                    subject to enhanced security due to its sensitive nature.
                  </Text>
                </Flex>

              </Box>


              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  HOW WE OBTAIN YOUR PERSONAL DATA
                </Text>
                <Flex
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="33px"
                  marginY="20px"
                  flexDirection="column"
                  gap="16px"
                >
                  <Text
                    fontWeight="400"
                    fontSize="19px"
                    lineHeight="30px"
                  >
                    There are a few ways in which we obtain your Personal Data:
                  </Text>

                  <UnorderedList
                    marginStart="30px"
                    fontWeight="400"
                    fontSize="19px"
                    lineHeight="30px"
                  >
                    <ListItem
                      marginBottom="12px"
                    >When you register to use and use the App.</ListItem>
                    <Text
                      fontWeight="400"
                      fontSize="19px"
                      marginLeft="30px"
                      marginY="16px"
                      lineHeight="30px"
                      textAlign="justify"
                    >
                      When you register to use and use our App, we receive and collect Personal Data
                      about you, such as your username, password, mobile device number, and device
                      information. We explain how we use this data in the charts below. Except for
                      the Personal Data that is deleted at the end of each session on the App (as noted
                      in the charts below), we keep your Personal Data (such as your registration
                      information) for as long as you are using the App. The only information that we
                      retain after your authorization to use the App has expired is your mobile phone
                      number, as discussed in more detail in the next section.
                    </Text>

                    <ListItem
                      marginBottom="12px"
                    >When you give us feedback or contact us through customer Support or otherwise.</ListItem>
                    <Text
                      fontWeight="400"
                      fontSize="19px"
                      marginLeft="30px"
                      marginY="16px"
                      lineHeight="30px"
                      textAlign="justify"
                    >
                      Information that you provide to our customer support team from
                      correspondence that you send to us, any conversations you have with us and any feedback that you give us may contain Personal Data, such as your
                      name and contact details. We maintain this data in the normal course of
                      business and, with respect to support inquiries, add information about
                      how we helped you to our database to help other users or to improve our
                      App. We keep the Personal Data associated with these communications
                      for no more than 3 years from the date of collection, except where legal
                      requirements mandate that we keep the information for a longer period,
                      in which case we comply with the law.
                    </Text>

                    <ListItem
                      marginBottom="12px"
                    >
                      When you use the App or communicate with us online.</ListItem>
                    <Text
                      fontWeight="400"
                      fontSize="19px"
                      marginLeft="30px"
                      marginY="16px"
                      lineHeight="30px"
                      textAlign="justify"
                    >
                      When you communicate with us online, small files called cookies may be
                      placed on your device to help identify you as an authorized user, to
                      identify the browser you are using, to analyze the efficiency of the App,
                      and for similar reasons. The information may also be used to gather
                      statistical information about usage of the App. You can refuse to accept
                      cookies by following the instructions provided by your browser. There
                      may be some differences in how the App performs if you do this. You can
                      also refuse cookies or choose the cookies you will accept by accessing the
                      portal on the Cookie banner. You can learn more about cookies at '
                      <a href="http://www.allaboutcookies.org" rel='noopener norefferer'
                        style={{ color: "#1e90ff", cursor: "pointer" }}>http://www.allaboutcookies.org</a>'
                      .
                    </Text>

                  </UnorderedList>

                </Flex>
              </Box>

              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  PERSONAL DATA COLLECTED BY US
                </Text>
                <Box
                  spacing={{ xxs: 2, xs: 4 }}>
                  <Text
                    fontWeight="400"
                    fontSize="19px"
                    lineHeight="30px"
                  // marginY="20px"
                  >
                    We collect the following types of Personal Data from you for the purposes as listed:
                  </Text>

                  <TableContainer style={{ width: "80vw" }}>
                    <Table variant='simple' layout="auto" marginY="16px" overflowX="auto">
                      <Thead>
                        <Tr>
                          <Th
                            fontSize="16px"
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                          >
                            Category of Data Subjects
                          </Th>
                          <Th
                            fontSize="16px"
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                          >
                            Types of Personal Data Processed
                          </Th>
                          <Th
                            fontSize="16px"
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                          >
                            Description/Purpose of Processing
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >
                            Users, Registrants
                          </Td>
                          <Td
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >
                            Registration Information
                          </Td>
                          <Td
                            style={{ minWidth: "100px", maxWidth: "200px" }}
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >
                            <Flex
                              flexDirection="column"
                            >
                              <Text
                                // maxWidth="50vw"
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >In connection with your registration to use the App,
                                we collect the following Personal Data:</Text>
                              <UnorderedList
                                marginStart="30px"
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >
                                <ListItem
                                  marginBottom="8px"
                                >Authorization</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Identification of the authorized device</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Identification of the authorized user:
                                  Username and password</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Device identifier</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Browser</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Mobile number</ListItem>
                              </UnorderedList>
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                                overflowWrap="break-word"
                                noOfLines={2}
                              >We use this information to ensure you are authorized to use the App and to sign you in to
                                access the App.We may also use this information
                                to identify you as an authorized user when you
                                reach out to customer support.</Text>
                            </Flex>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td
                            width="25%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Users: Measurement Subjects</Td>
                          <Td
                            width="25%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Biometric Data (as
                            listed in the next
                            column)</Td>
                          <Td
                            width="45%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px">
                            <Flex
                              flexDirection="column"
                            >
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >When the App is in use, we capture, but do not
                                store or maintain beyond the use session,</Text>
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >images and video through supported mobile device
                                cameras for the purpose of extracting
                                and analyzing the following information:</Text>

                              <UnorderedList
                                marginStart="30px"
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >
                                <ListItem
                                  marginBottom="8px"
                                >Facial blood flow</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Facial landmarks and features</ListItem>

                                <Text
                                  fontWeight="400"
                                  fontSize="19px"
                                  lineHeight="30px"
                                >We use this data to generate information about you
                                  and your potential health conditions. This ensures
                                  that the Measurement Results are tailored to you.
                                  This biometric data is Sensitive Personal Data and
                                  is not stored in the App.</Text>
                              </UnorderedList>
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                                overflowWrap="break-word"
                                noOfLines={2}
                              >We use this information to ensure you are authorized to use the App and to sign you in to
                                access the App.We may also use this information
                                to identify you as an authorized user when you
                                reach out to customer support.</Text>
                            </Flex>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td
                            width="25%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Users: Measurement Subjects</Td>
                          <Td
                            width="25vw"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Measurement
                            Results (as listed in
                            the next column)</Td>
                          <Td
                            width="45%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px">
                            <Flex
                              flexDirection="column"
                            >
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >Biometric Data is used to generate the following
                                Personal Data outputs, or Measurement Results, from
                                the App:</Text>

                              <UnorderedList
                                marginStart="30px"
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >
                                <ListItem
                                  marginBottom="8px"
                                >heart rate</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >breathing rate</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >blood pressure</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >body mass index</ListItem>

                              </UnorderedList>
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >These data points are the output generated from
                                utilization of the App and are personalized to you.
                                Measurement Results are not stored in the App.</Text>
                            </Flex>
                          </Td>
                        </Tr>

                        <Tr>
                          <Td
                            width="25%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Users: Measurement Subjects</Td>
                          <Td
                            width="10vw"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px"
                          >Demographic Data
                            and general health
                            metrics (as listed in
                            the next column)</Td>
                          <Td
                            width="45%"
                            fontWeight="400"
                            fontSize="19px"
                            lineHeight="30px">
                            <Flex
                              flexDirection="column"
                            >
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >To achieve more accurate Measurement results, the
                                App requires the User to provide certain additional
                                Personal Data points:</Text>

                              <UnorderedList
                                marginStart="30px"
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >
                                <ListItem
                                  marginBottom="8px"
                                >Age</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Weight</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Gender</ListItem>
                                <ListItem
                                  marginBottom="8px"
                                >Height</ListItem>

                              </UnorderedList>
                              <Text
                                fontWeight="400"
                                fontSize="19px"
                                lineHeight="30px"
                              >The more accurate these inputs, the more specific and
                                tailored to you Measurement results will be. The
                                demographic data and general health metrics are Sensitive
                                Personal Data and are not stored in the App.</Text>
                            </Flex>
                          </Td>
                        </Tr>

                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>

              <Flex gap="16px"
                flexDirection="column">
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  For avoidance of doubt, we use the Personal Data you provide or which we collect
                  solely for the purpose of completing a CDL Health Scan, providing you access to the App
                  and providing you with support. All Biometric Data and other Sensitive Personal Data generated
                  by the App or which you enter into the App are deleted from our servers once a reading is complete.
                  We have included below a further explanation regarding the ways in which we utilize your
                  Personal data:
                </Text>

                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="8px"
                  >Purposes: to provide you with the service described in our Terms and Conditions and
                    thus produce those measurements and calculations (i.e., the creation of wellness
                    measurements based on the extraction and analysis of your facial blood flow and
                    facial mapping data) that are requested, while also providing you with customer
                    support.</ListItem>
                  <ListItem
                    marginBottom="8px"
                  >Types of Data Collected and Generated: Cellphone number, Demographic Data
                    (age/weight/gender/height), Biometric Data (e.g., blood pressure, heart rate, and
                    respiration rate).</ListItem>
                  <ListItem
                    marginBottom="8px"
                  >Retention Period: This is a web-based app, so your Personal Data such as height,
                    weight, age and cell number are stored solely on your device and through the
                    browser that you use to access the App.
                    <span style={{ fontWeight: 600 }}> ONCE YOU ARE NO LONGER AUTHORIZED TO
                      USE THE APP, OUR SERVERS RETAIN NONE OF YOUR PERSONAL DATA EXCEPT FOR
                      THE CONTINUED PRESENCE OF YOUR CELL PHONE NUMBER IN ELECTRONIC
                      FORMAT</span>. That number is deleted once the applicable data retention period
                    expires (3 years after your authorization ends or any longer period required by
                    applicable law).
                  </ListItem>

                </UnorderedList>

              </Flex>

              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  textDecoration="underline"
                  marginTop="18px"
                  marginBottom="12px"
                >
                  COMPLYING WITH LEGAL OBLIGATIONS TO DISCLOSE PERSONAL DATA
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                // marginY="20px"
                >
                  We may use your Personal Data in good faith if and when we are required to do so to comply
                  with legal or regulatory obligations (including to comply with applicable laws and regulations,
                  subpoenas and search warrants, court or regulatory orders, or other valid legal processes), to
                  establish or exercise our rights, and to defend against legal actions. While we delete the health
                  information which you enter in the App or which the App produces after each scan is complete, there may be a time when we will provide your mobile number, which is maintained, to an
                  applicable public authority. For example, we may come under a legal obligation to prove that
                  we do not store Sensitive Personal Data of Users of the App, and we may be obligated to
                  cooperate by allowing investigators to access certain user accounts of the App, which could
                  expose your mobile number.
                </Text>
              </Box>

              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  textDecoration="underline"
                  marginTop="18px"
                  marginBottom="12px"
                >
                  DISCLOSURE OF PERSONAL DATA IN CONNECTION WITH SALE OF BUSINESS AND TO THIRD-

                  PARTY CONTRACTORS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                  marginBottom="6px"
                >
                  We may disclose your Personal Data (again, limited to your mobile number) with third parties in
                  connection with a change in control or financial status of CDL, including a corporate
                  restructuring, sale, acquisition, financing, reorganization, bankruptcy, receivership, transfer or
                  assignment of assets or business merger or divestiture. Personal Data and other information
                  may be shared in the diligence process with counterparties and others assisting with the
                  transaction and transferred to a successor affiliate as part of that transaction.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                  marginBottom="6px"
                >
                  We may also disclose your Personal Data to independent contractors, consultants or service
                  providers (“Contractors”) to provide specific services related to the App, such as development
                  and support. Access to Personal Data by these Contractors is limited to only that information
                  which they need to know to perform their services. The Contractors are required to protect the
                  privacy of Personal Data in a manner consistent with this Privacy Policy and our general
                  practices around Personal Data and Security, as well as consistent with applicable laws and
                  regulations.
                </Text>
              </Box>

              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  NO SALE OR SHARING OF YOUR PERSONAL INFORMATION
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Except as otherwise specifically noted in this Privacy Policy, we do not and will not engage in
                  the sale or sharing (as defined under the California Consumer Privacy Act) of your Personal Data
                  with any other party.
                </Text>
              </Box>

              <Box>
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  HOW WE PROTECT YOUR PERSONAL DATA
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We maintain industry standard technical and organizational measures to protect your Personal
                  Data from loss, misuse, alteration, or unintended destruction. We have adopted various
                  security measures to protect Personal Information and general information we receive from or
                  generate for Users and Registrants. The App has been designed to require minimal retention of
                  Personal Data.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Nonetheless despite our efforts to protect your Personal Data, you should be aware that there
                  is always some risk that bad actors could find a way to circumvent our technical and
                  organization measures designed to prevent that occurrence. We do not represent or warrant
                  that unauthorized access will never occur or that the privacy and security of any information
                  transmitted to us and that we generate about you will never be breached. Your use of the App
                  is at your own risk.
                </Text>
              </Box>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  YOUR PRIVACY RIGHTS UNDER APPLICABLE LAW
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We maintain industry standard technical and organizational measures to protect your Personal
                  Data from loss, misuse, alteration, or unintended destruction. We have adopted various
                  security measures to protect Personal Information and general information we receive from or
                  generate for Users and Registrants. The App has been designed to require minimal retention of
                  Personal Data.
                </Text>
                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Withdraw your consent to our processing of your Personal Data;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Obtain copies of the Personal Data we maintain about you and information regarding the
                    processing of your data;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Correct any inaccurate information we retain;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Restrict the processing of your Personal Data, subject to certain restrictions;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Object to the processing of your Personal Data, subject to certain restrictions;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Request deletion of your Personal Data held by us;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Ask us to transfer your Personal Data to another entity under certain circumstances;</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Be free from retaliatory action in response to your exercise of these rights; and</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Make a complaint to a privacy regulator.d</ListItem>

                </UnorderedList>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  The foregoing list may not be exhaustive, as U.S. states continue to pass data privacy laws that
                  may add additional rights to this list.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Given that we only maintain your mobile number and you control the processing of most of
                  your Personal Data by your decision to use or not use the App at any time, some of these
                  rights may not strictly apply in the circumstances of the App. Nonetheless, you should be
                  aware of these rights.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  In order to protect you and your Personal Data, we may require evidence to confirm your
                  identity prior to responding to the exercise of the rights above. We will respond to your request
                  to exercise your rights within the time frame allowed by applicable law. You may exercise these
                  rights by accessing the privacy rights portal at <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/privacynotice
                  </span>.
                </Text>
              </Flex>

              <Flex
                flexDirection="column"
                gap="10px">

                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  CALIFORNIA PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  If you are a California resident, the rights you may have with respect to data privacy are set
                  forth in this section.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Please see the sections above with respect to the Personal Data we collect about you and how
                  we use the data.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Under California law, you have a right to notice upon collection of the categories of personal
                  information collected by us and the purposes for which that Personal Data will be used. We
                  have provided notice through this Privacy Policy. You may also have the following rights:
                </Text>

                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Access: You have a right to access a copy of the Personal Data we have collected about You.</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Correction: You have the right to request correction of inaccurate Personal Data we
                    have about you.</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Deletion: You have a right to request deletion or anonymization of your Personal Data, with some exceptions.</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Sale/Sharing: You have the right to opt out of the sale or sharing of your Personal Data
                    by us. As noted in this Privacy Policy, we do not engage in such practices.</ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Right to Limit Use: You have the right to limit the use of your Sensitive Personal Data
                    when it is used to infer characteristics about you. This usually applies to marketing
                    purposes. We do not engage in such practices.</ListItem>
                </UnorderedList>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  You can access these rights through the Privacy Rights Portal, which can be found at
                  <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/privacynotice
                  </span>. Note, however, that some of the rights listed
                  above may not apply to your registration and use of the App because we maintain very little
                  personal information about you.
                </Text>

              </Flex>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  COLORADO PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                // marginY="20px"
                >
                  If you are a Colorado resident, additional rights available to you can be found here. This section
                  supplements the rights set forth in the balance of the Privacy Policy with those additional rights
                  provided by Colorado law.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  The Personal Data we collect about you is set forth above, as is information on how we use that
                  information.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We do not use your Personal Data to make decisions with legal or similar effects for you based
                  on automated processing of the data.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We have explained above how we may share your Personal Data with third parties in the event
                  of a legal requirement to do so, in the event of sale, reorganization or other change in our
                  company, and in the event we hire certain third-party contractors.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Subject to limitations, Colorado residents have the following rights:
                </Text>

                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Access: You have a right to access a copy of the specific Personal Data we have collected
                    about you.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Portability: You have a right to receive your Personal Data in a portable readily usable
                    format, to the extent possible.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Correction: You have the right to request correction of inaccurate personal information,
                    taking into account the nature of the information and the purposes of the processing.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Deletion: You have the right to request that we delete or anonymize your personal
                    information.</ListItem>

                </UnorderedList>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We do not sell or share your personal information, except as specifically noted above. Such
                  sharing would not be for targeted advertising or sale of Personal Data. While you technically
                  have a right to opt out of the sale or sharing of your Personal Data for targeted advertising, this
                  right does not specifically apply in this instance where no such sale or sharing occurs. Like this
                  right, some of the rights listed above may not apply to your registration and use of the App
                  because we maintain very little personal information about you.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  To exercise any of these rights, please access the Privacy Right Portal which can be found here:
                  <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/privacynotice
                  </span>.
                </Text>

              </Flex>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  CONNECTICUT PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                // marginY="20px"
                >
                  The following section describes rights you may have under Connecticut law. Again, these rights
                  must be read in context of the information provided elsewhere in this Privacy Policy regarding
                  the types of Personal Data collected, processed and stored and how we use Personal Data.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We do not use your Personal Data to make decisions with legal or similar effects for you based
                  on automated processing of the data.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Subject to certain limitations, your rights under Connecticut law are the following:
                </Text>

                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Confirmation of the Processing and Access: You have the right to confirm whether we
                    process Personal Data about you and to access a copy of the specific pieces of
                    information that we have collected about you. Note that we do not store or save your
                    Personal Data with the exception of your mobile number.

                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Portability: When you exercise your right to access, you also have a right to receive your
                    Personal Data in a portable and easily readable format, if technically feasible.

                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Correction: You have a right to request correction of inaccurate Personal Data we hold
                    about you, taking into account the nature of the Personal Data and the purposes of the
                    processing.

                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Deletion: You have the right to request the deletion or anonymization of your Personal
                    Data, subject to exceptions.</ListItem>

                </UnorderedList>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  We do not sell or share your personal information, except as specifically noted above. Such
                  sharing would not be for targeted advertising or sale. Nonetheless, you technically have a right
                  to opt out of the sale or sharing of your Personal Data for targeted advertising or sale, even
                  though we do not engage in such practices. Like this right, some of the rights listed above may
                  not apply to your registration and use of the App because we maintain very little personal
                  information about you.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  To exercise any of these rights, please access the Privacy Right Portal which can be found here:
                  <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/notice
                  </span>.
                </Text>

              </Flex>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  UTAH PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                // marginY="20px"
                >
                  Utah also provides privacy rights to its residents which become effective on December 31,
                  2023. This information is in addition to information provided elsewhere in this Privacy Policy
                  regarding Personal Data we collect, process and store and how we use your Personal Data.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Subject to certain limitations, your rights under Utah law include the following:
                </Text>

                <UnorderedList
                  marginStart="30px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Confirmation of Processing and Access: You have the right to confirm whether we
                    process Personal Data about you and to access a copy of the specific Personal Data we have collected, used or disclosed.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Portability: You have the right to access your Personal Data and to receive a copy of it in
                    a portable and usable format, to the extent technically feasible.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Deletion: You have the right to have us delete or anonymize your Personal Data, with
                    some exceptions.
                  </ListItem>

                </UnorderedList>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  You have a right to opt out of targeted advertising and the sale of your Personal Data. As noted
                  elsewhere in this Privacy Policy, we do not engage in such activities. Like this right, some of the
                  rights listed above may not apply to your registration and use of the App because we maintain
                  very little personal information about you.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  You can submit a request to exercise these rights by accessing the privacy rights portal at :
                  <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/privacynotice
                  </span>. After you submit a request, we will verify
                  your request to the extent possible. We may ask for additional, reasonable information from
                  you, given the nature of your request as part of that process. If we deny your request, you can
                  appeal it within thirty (30) days from the denial by contacting the Data Protection Officer (DPO)
                  by email to set forth in the “Contact Us About Your Privacy Rights” section below. You can also
                  contact the DPO if you have any questions about the foregoing rights.
                </Text>

              </Flex>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  VIRGINIA PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                // marginY="20px"
                >
                  This section describes the rights Virginia residents may have with respect to our collection,
                  processing and storage of Personal Data and must be read in the context of the rest of this
                  Privacy Policy.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Virginia residents have the following rights:
                </Text>

                <UnorderedList
                  marginStart="3px"
                  fontWeight="400"
                  fontSize="19px"
                  lineHeight="30px"
                >
                  <ListItem
                    marginBottom="4px"
                  >Confirmation of Processing and Access: You have the right to confirm whether we
                    process your Personal Data and to access a copy of the specific types of personal
                    information we collect.

                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >Portability: When you exercise your right to access, you have a right to receive your
                    Personal Data in a portable and readily usable format, to the extent technically feasible.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >
                    Correction: You may have the right to request correction of your Personal Data, taking
                    into account the nature of Personal Data and the purpose of the processing.
                  </ListItem>
                  <ListItem
                    marginBottom="4px"
                  >
                    Deletion: You may have the right to the deletion or anonymization of your Personal Data,
                    with some exceptions..
                  </ListItem>

                </UnorderedList>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  As with the other State laws, you have the right to opt out of targeted advertising and sale of
                  your Personal Data, though we do not engage in either of those practices. Like this right, some
                  of the rights listed above may not apply to your registration and use of the App because we
                  maintain very little personal information about you.
                </Text>

                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  You can submit a request to exercise these rights by accessing the privacy rights portal at:
                  <span style={{ fontWeight: 700, color: "#1e90ff", cursor: "pointer", wordBreak: "break-word" }}
                    onClick={() => navigate('/privacynotice')}
                  >
                    https://hub.healthscanner.app/privacynotice
                  </span>
                  . After you submit a request, we will verify your request to the extent possible. We may ask for additional, reasonable information from
                  you, given the nature of your request as part of that process. If we deny your request, you can
                  appeal it within thirty (30) days from the denial by contacting the Data Protection Officer (DPO),
                  as set forth in the “Contact Us About Your Privacy Rights” section below. You can also contact
                  the DPO if you have any questions about the foregoing rights.
                </Text>

              </Flex>

              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  textAlign="center"
                  marginY="14px"
                  textDecoration="underline"
                >
                  CONTACT US ABOUT YOUR PRIVACY RIGHTS
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="35px"
                >
                  CDL has appointed a Data Protection Officer or DPO, who is responsible for overseeing our
                  privacy practices and answering your questions in relation to this Privacy Policy. The DPO will
                  address any complaints you may have regarding the protection or processing of your Personal
                  Data by us. If you have any questions or would like to exercise your rights or appeal a denial of
                  a request to exercise your rights, please contact the DPO using the following email address or
                  street address:
                  <a href="mailto:privacy@healthintransportation.com" style={{ fontWeight: 600, color: "#1e90ff", cursor: "pointer" }}> privacy@healthintransportation.com </a>  or CDL Health Scanner LLC, 5130 S. Fort
                  Apache Road, Suite 215-231, Las Vegas, NV 89148.
                </Text>
                <Text
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >
                  Note: <i>The only information CDL holds for users of its app is a name, a registered phone number
                    and an email address. That information is never shared and is only utilized at the time the app is
                    first authorized and downloaded. If a user decides, by activating the DPO process, to delete that
                    information, the associated account will be automatically terminated.</i>
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                gap="10px"
              >
                <Text color="black"
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px">There are two different ways that Personal Data may be collected, both of which involve Direct interactions. You may give us your Personal Information (sensitive data included) by activating the App or by corresponding with us by post, email or otherwise. This includes Personal Information you provide when you:</Text>
                <Text color="black"
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >use the app on your IOS or Android smartphone - when you the App, we may receive or collect information or data about you.</Text>
                <Text color="black"
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px"
                >Give us feedback or contact us (Customer Support). Any information that you provide to our customer support team from the correspondence that you send to us, any conversations you have with us and any feedback that you give us.</Text>

                <Text color="black"
                  fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px">We use the Personal Information you provide or which we collect for the purposes of completing a CDL Health Scan, or as otherwise noted in this Notice an our Policy. </Text>

                <Text color="black" as="i" fontWeight="400"
                  fontSize="19px"
                  marginTop="12px"
                  lineHeight="30px"
                >Legal Obligations:</Text>
                <Text color="black" fontWeight="400"
                  fontSize="19px"
                  marginTop="10px"
                  lineHeight="30px">There are therefore potential circumstances in which that information may have to be shared with the applicable legal authority. For example, we may come under a legal obligation to prove that we store no personal biometric data and we would be obligated to cooperate by allowing investigators to access certain users of the App.</Text>
              </Flex>

              <Checkbox colorScheme='green' isChecked={checkedItem} onChange={(e) => userSelected(e.target.checked)}>
                I hereby acknowledge my acceptance of this <b>Privacy Policy</b>
              </Checkbox>

              <Flex width="100%" justifyContent="center" marginTop="20px">
                <Box
                  as='button'
                  bg={btnDisabled ? "gray" : "red"}
                  color="white"
                  mt={{
                    vxs: "1 !important",
                    xxs: "1 !important",
                    xs: "auto !important",
                  }}
                  mb={{ xs: "5 !important" }}
                  w="20%"
                  fontSize={{ vxs: 14, xs: 18 }}
                  _hover={{ bg: btnDisabled ? "gray" : "red" }}
                  _focus={{ bg: btnDisabled ? "gray" : "red" }}
                  _active={{ bg: btnDisabled ? "gray" : "red" }}
                  onClick={closeTocModal}
                  height="40px"
                  disabled={btnDisabled}
                >
                  Close
                </Box>
              </Flex>

            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal >
    </>
  );

};

export default PrivacyPolicy;
