import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  PinInput,
  PinInputField,
  Text,
  useToast,
  VStack,
  Flex,
  Box
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PageMid from "../components/PageMid";
import PageTop from "../components/PageTop";
import axios from "axios";
import { saveLogin, getLogin, removeLogin } from "../components/cachestorage";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const OTPVerification = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [isError, setIsError] = useState(false);
  const OtpSwal = withReactContent(Swal);

  const toast = useToast();

  useEffect(() => {
    (async () => {
      try {
        const resCountry = await fetch("https://ipapi.co/json/");
        const countryJSON = await resCountry.json();
        setUserCountry(countryJSON.country);
      } catch (err) {
        console.log("ERROR:: ", err);
        console.log("Unable to fetch country");
      }
    })();
  }, []);

  const handleBack = () => {
    localStorage.removeItem("otpVerified");
    removeLogin();
    navigate("/");
  };

  const login = async (username, password) => {
    try {
      const res = await axios.get(
        `https://hub.healthscanner.app/service/login?userName=${userCountry && userCountry === "IN" ? 91 : 1
        }${username}&passcode=${password}`
      );

      if (res.data.message === "Success") {
        console.log("in if");
        localStorage.setItem("otpVerified", "true");
        navigate("/bp1");
      }
    } catch (err) {
      console.log("ERROR:: ", err);
      setIsError(true);
      toast({
        title: "Invalid authorization code",
        description: "Please retry",
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleSubmit = async () => {
    if (!otp || otp?.length !== 6) {
      //return alert("Please enter the otp");
      return OtpSwal.fire({
        title: 'Error!',
        text: 'Please enter the otp',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red'
      });
    }
    const loginDetails = JSON.parse(localStorage.getItem("login"));
    const promoCode = document.getElementById("promo-code").value;
    if (promoCode) {
      localStorage.setItem("promoCode", promoCode);
    }
    try {
      setIsSubmitLoading(true);
      const res = await axios({
        method: "POST",
        url: `https://hub.healthscanner.app/service/registerEx?userName=${userCountry && userCountry === "IN" ? 91 : 1
          }${loginDetails.username}&otp=${otp}&passcode=${loginDetails.password
          }&regDateTime=${new Date().toISOString()}`,
      });

      if (!res.data.registerDateTime) {
        throw new Error("Invalid Verification Code Or OTP Expired. Please retry.");
      }

      if (localStorage.getItem("login")) {
        const loginObj = JSON.parse(localStorage.getItem("login"));
        localStorage.setItem(
          "login",
          JSON.stringify({ ...loginObj, userId: res.data.userId || 0 })
        );
        //set cache here too
        saveLogin(JSON.stringify({ ...loginObj, userId: res.data.userId || 0 }));
      }
      localStorage.setItem("otpVerified", "true");
      navigate("/bp1");
    } catch (err) {
      console.log("ERROR:: ", err);
      // console.log("ERROR msg:: ", err.response.data.message);
      if (
        err &&
        err?.response?.data?.message?.toLowerCase() ===
        "username already exists"
      ) {
        // const res = await axios.get(
        //   `https://healthscanner.app/service/login?userName=${
        //     userCountry && userCountry === "IN" ? 91 : 1
        //   }${loginDetails.username}&passcode=${loginDetails.password}`
        // );

        // if (res.data.message === "Success") {
        //   console.log("in if");
        //   localStorage.setItem("otpVerified", "true");
        //   navigate("/bp1");
        // }
        await login(loginDetails.username, loginDetails.password);
      } else {
        localStorage.removeItem("otpVerified");
        setIsError(true);
        toast({
          title: `${err.message
            ? err.message.includes("500")
              ? "An error has occured"
              : err.message
            : "Error"
            }`,
          description: "Please retry",
          status: "error",
          duration: 5000,
        });
      }
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    const loginDetails = localStorage.getItem("login");
    const otpVerified = localStorage.getItem("otpVerified");

    if (otpVerified && loginDetails) {
      return navigate("/bp1");
    } else if (!loginDetails) {
      return navigate("/");
    }
  }, []);

  return (
    <>
      <PageTop heading="Single-Use Verification Code" />
      <PageMid extraStyles={{ spacing: 5, pt: 10 }}
      >
        <Box
          paddingX={{ vxs: "1.5rem", xxxs: "2rem", xs: "4rem" }}
        >
          <Text
            color="white"
            fontWeight="bold"
            fontSize={{ vxs: 18, xxs: 20, xs: 22 }}
            textAlign="center"
            w="100%"
            letterSpacing={0.5}
            mb={{ vxs: "3 !important", xs: "5 !important" }}
          >
            Check your text message for a personalized six digit verification
            code.
          </Text>
          <FormControl>
            <FormLabel color="white" fontSize={{ vxs: 18, xxxs: 20 }} marginY={5}>
              Enter your 6 digit code
            </FormLabel>
            <HStack justifyContent="space-around">
              <PinInput
                size={{ vxs: "md", xxxs: "lg" }}
                onComplete={(e) => setOtp(e)}
              >
                <PinInputField color="white" fontSize={20} />
                <PinInputField color="white" fontSize={20} />
                <PinInputField color="white" fontSize={20} />
                <PinInputField color="white" fontSize={20} />
                <PinInputField color="white" fontSize={20} />
                <PinInputField color="white" fontSize={20} />
              </PinInput>
            </HStack>
            <VStack mt="5">
              <Text color="white" fontSize={16} textAlign="left" w="100%">
                Promo code
              </Text>
              <Input
                type="text"
                id="promo-code"
                mt="5"
                placeholder="Please enter a promo code"
                color="white"
                fontSize={16}
              />
            </VStack>
            <VStack justifyContent="center">
              <Flex style={{ color: "white", paddingTop: "20px", fontSize: "0.9rem" }}>
                For some IOS/iPhones users there may be a requirement to re-register for the second use of the app, but a TAP HERE function will provide an authorization-code reminder.
              </Flex>
              <Flex style={{ color: "white", paddingTop: "20px", fontSize: "0.9rem" }}>
                After that, the app will open automatically, without the need for registration screens; as it will for the second use on all Android devices and most Apple phones
              </Flex>
            </VStack>
            <HStack justifyContent="center">
              {isError && (
                <Button
                  bg="red"
                  color="white"
                  mt={{ vxs: "8 !important", xs: "10 !important" }}
                  mb={{ xs: "5 !important" }}
                  fontSize={{ vxs: 15, xs: 18 }}
                  _hover={{ bg: "red" }}
                  _focus={{ bg: "red" }}
                  _active={{ bg: "red" }}
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}

              <Button
                bg="red"
                color="white"
                mt={{ vxs: "8 !important", xs: "10 !important" }}
                mb={{ xs: "5 !important" }}
                fontSize={{ vxs: 15, xs: 18 }}
                _hover={{ bg: "red" }}
                _focus={{ bg: "red" }}
                _active={{ bg: "red" }}
                type="submit"
                onClick={handleSubmit}
                isLoading={isSubmitLoading}
              >
                Submit
              </Button>
            </HStack>
          </FormControl>
        </Box>
      </PageMid>
    </>
  );
};

export default OTPVerification;
