import { Navigate, Route, Routes } from "react-router";
import PrivateRoute from "./routes/PrivateRoute";
import BloodPressureInfo1 from "./pages/BloodPressureInfo1";
import BloodPressureInfo2 from "./pages/BloodPressureInfo2";
import BMIResult from "./pages/BMIResult";
import BPResult from "./pages/BPResult";
import CheckBMI from "./pages/checkBMI";
import Finish from "./pages/Finish";
import Login from "./pages/Login";
import PDriverInfo from "./pages/PDriverInfo";
import PublicRoute from "./routes/PublicRoute";
import Dashboard from "./pages/Dashboard";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import OSADanger from "./pages/OSADanger";
import OTPVerification from "./pages/OTPVerification";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <AddToHomeScreen />
            <Login />
          </>
          // <Login />
        }
      />
      <Route path="otp-verify" element={<OTPVerification />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/termsofuse" element={<TermsOfUse />} />
      <Route path="/privacynotice" element={<PrivacyPolicy />} />
      <Route
        path="/bp1"
        element={
          <PrivateRoute>
            <BloodPressureInfo1 />
          </PrivateRoute>
        }
      />
      <Route
        path="/bp2"
        element={
          <PrivateRoute>
            <BloodPressureInfo2 />
          </PrivateRoute>
        }
      />
      <Route
        path="/bp-result"
        element={
          <PrivateRoute>
            <BPResult />
          </PrivateRoute>
        }
      />
      <Route
        path="/pdriver-info"
        element={
          <PrivateRoute>
            <PDriverInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/check-bmi"
        element={
          <PrivateRoute>
            <CheckBMI />
          </PrivateRoute>
        }
      />
      <Route
        path="/result-bmi"
        element={
          <PrivateRoute>
            <BMIResult />
          </PrivateRoute>
        }
      />
      <Route
        path="/wim"
        element={
          <PrivateRoute>
            <Finish />
          </PrivateRoute>
        }
      />
      <Route
        path="/finish"
        element={
          <PrivateRoute>
            <OSADanger />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
