import { useState, useEffect, useRef } from "react";
import { Box, Button, Text } from "@chakra-ui/react";

import PageMid from "../components/PageMid";
import PageTopHUB from "../components/PageTopHUB";
import Chart from "../components/chart";
import { useNavigate } from "react-router";

const BMIResult = () => {
  const [bmiResult, setBmiResult] = useState();

  const navigate = useNavigate();

  const bottomRef = useRef();

  useEffect(() => {
    const bmi = localStorage.getItem("bmi");

    if (bmi) setBmiResult(JSON.parse(bmi));

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTopHUB heading="BMI Result" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <Box
          bg="rgb(184, 9, 9)"
          color="white"
          p="5"
          mb={{ xxxs: "6", xs: "8", sm: "10" }}
        >
          <Text textAlign="center" mb="3" fontSize={{ vxs: 14, xs: 20 }}>
            Your current BMI is
          </Text>
          <Text fontSize={{ vxs: 16, xxxs: 20 }} textAlign="center">
            {bmiResult || "NA"}
          </Text>
          <Chart />
        </Box>
        <Box
          color="white"
          fontSize={{ vxs: 12, xxxs: 14, xs: 18 }}
          textAlign="justify"
        >
          <Text mb={{ vxs: "4", xxxs: "6", xs: "8", sm: "10" }}>
            A high BMI is often an indication of a pre-diabetic condition or of
            the existence of type2 diabetes. It is vital that a driver in this
            situation consult his/her physician.
          </Text>
          <Text>
            The DOT regard a driver with a BMI reading of over 30 as obese and
            therefore at much higher risk for diabetes. The exact statistical
            risk is age-related, but even the youngest driver has a 70% lifetime
            risk of diabetes with a BMI of 35 or above.
          </Text>
        </Box>
        <Button
          ref={bottomRef}
          mt="auto !important"
          mb="3 !important"
          w="100%"
          bg="red"
          color="white"
          fontSize={{ vxs: 14, xxxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={() => navigate("/wim")}
        >
          NEXT - Getting Help
        </Button>
      </PageMid>
    </>
  );
};

export default BMIResult;
