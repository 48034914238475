import { Button, Image, Link, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import PageMid from "../components/PageMid";
import PageTopHUB from "../components/PageTopHUB";
import sleepLogo from "../assets/Sleep-Logo.jpeg";
import { useRef } from "react";

const OSADanger = () => {
  const navigate = useNavigate();

  const bottomRef = useRef();

  const handleFinish = () => {
    localStorage.setItem("bmi", "");
    localStorage.setItem("ageGender", "");
    navigate("/");
  };

  return (
    <>
      <PageTopHUB heading="Sleep Apnea Issues" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <VStack
          color="white"
          fontSize={{ vxs: 13, xxxs: 15, xxs: 14, xs: 16, sm: 19 }}
          spacing={{ vxs: 2, xxxs: 3.5, xs: 6 }}
        >
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            A driver with a BMI of 35 or more will only be issued a thirty-day
            card, pending a supervised sleep study, because disrupted sleep
            patterns can result in fatal accidents. (Note: The DOT also regards
            30 as “high risk” for sleep apnea so many examiners use that
            figure.)
          </Text>
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            Similarly, thirty-days-only is mandated for male drivers with a neck
            size of 17 inches (female, 16 inches) but many medical examiners use
            their official discretion to, instead, apply a lower 16/15 standard.
            For more information on all this, visit{" "}
            <Link
              href="https://dedicatedsleep.net/transportation/"
              color="blue.400"
              borderBottom="1px solid"
              isExternal
            >
              https://dedicatedsleep.net/transportation/
            </Link>
          </Text>
          <Image src={sleepLogo} w={150} mt="0 !important" mb="3 !important" />
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            <i>Dedicated Sleep LLC</i> is a respected practitioner in the sleep
            apnea field and offers drivers a free consultation with a qualified
            expert who has worked with professional drivers for many years. If
            you need help, please contact Sally Austin at: <br />
            <Text textAlign="center">
              Email:{" "}
              <Link
                href="mailto:saustin@dedicatedsleep.net"
                color="blue.400"
                borderBottom="1px solid"
              >
                saustin@dedicatedsleep.net
              </Link>
              <br />
              or <br /> Cellphone: (503) 440-5655{" "}
            </Text>
          </Text>
        </VStack>
        <Button
          ref={bottomRef}
          mt={{
            vxs: "4 !important",
            xxxs: "auto !important",
            sm: "auto !important",
          }}
          mb="5 !important"
          w="100%"
          bg="red"
          color="white"
          p="6"
          fontSize={{ vxs: 14, xs: 18 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={handleFinish}
        >
          Scan Completed - Check again in 5 days
        </Button>
      </PageMid>
    </>
  );
};

export default OSADanger;
