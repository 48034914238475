import { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
  Icon,
  PinInput,
  HStack,
  PinInputField,
  Image,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Checkbox,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import queryString from "query-string";
import { Buffer } from "buffer";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import "@fontsource/roboto-mono";
import {
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineInfoCircle,
} from "react-icons/ai";
// import topImg from "../assets/cdl_top.png";
import topImg from "../assets/bi-top-new-logo-hub.png";
import biBg from "../assets/bi.png";
import biInvert from "../assets/bi_invert.png";
import { Tooltip } from "recharts";
import { saveLogin, getLogin, removeLogin } from "../components/cachestorage";
// import { sendMessage } from "../components/messagechannel";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import TermsOfUse from "../components/TermsOfUse";
import PrivacyPolicy from "../components/PrivacyPolicy";
// import { getItem, setItem } from "localforage";

const Login = () => {
  const [username, setUsername] = useState();
  const [displayUsername, setDisplayUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isLoginBtnLoading, setIsLoginBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const [userCountry, setUserCountry] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const params = queryString.parse(location.search);
  const LoginSwal = withReactContent(Swal);
  const [phoneInput, setPhoneInput] = useState();
  const [tocModalIsOpen, setTocModalIsOpen] = useState(false);
  const [ppModalIsOpen, setPpModalIsOpen] = useState(false);
  const [acceptTermsAndPrivacyPolicy, setAcceptTermsAndPrivacyPolicy] = useState(false);

  const [tocItem, setTocItem] = useState(false);
  const [privacyItem, setPrivacyItem] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    if (typeof (phoneInput) !== 'undefined') {
      if (phoneInput.length === 12) {
        let result = phoneInput.substring(2, 12);
        const formattedNo = handlePhoneNumberFormatting(result);
        setDisplayUsername(formattedNo);
        setUsername(result);
        setUserCountry("US"); //seting default value
      }
    }
  }, [phoneInput]);

  useEffect(() => {
    /*  (async () => {
        try {
          const resCountry = await fetch("https://ipapi.co/json/");
          const countryJSON = await resCountry.json();
          setUserCountry(countryJSON.country);
        } catch (err) {
          console.log("ERROR:: ", err);
          console.log("Unable to fetch country");
        }
      })(); */

    setUserCountry("US");
    if (params.auth_code && params.phone) { //addressing new requirement
      setUsername(params.phone);
      setDisplayUsername(handlePhoneNumberFormatting(params.phone));
      setPassword(params.auth_code);
      setConfirmPassword(params.auth_code);
      setUserCountry("US");
      // Clear cache here as new login attempted
      // Removing clear cache code
      // removeLogin();
      // localStorage.removeItem("login");
      // localStorage.removeItem("otpVerified");
      // localStorage.removeItem("promoCode");
    }

    if (params.results) {
      const resultsBuffer = Buffer.from(params.results, "base64");
      const resultsObj = JSON.parse(resultsBuffer.toString());
      localStorage.setItem("results", JSON.stringify(resultsObj));
      return navigate("/bp-result");
    }

    var cacheLogin, cacheOtp;
    (async () => {
      try {
        if (!localStorage.getItem("login")) {
          //Getting the user info from cache
          cacheLogin = await getLogin();
          // alert(cacheLogin);
          // cacheOtp = await getOtp();

          // getItem("test").then((val) => {
          //   console.log("got: ", val);
          // });

          if (cacheLogin) {
            const loginDetails = JSON.parse(cacheLogin);
            setUsername(loginDetails.username);
            setDisplayUsername(handlePhoneNumberFormatting(loginDetails.username));
            setPassword(loginDetails.password);
            setConfirmPassword(loginDetails.password);
            //set localStorage here
            //Check if local storage has login info or not

            localStorage.setItem("login", cacheLogin);
            localStorage.setItem("otpVerified", "true");
          } else { //offline cache for PWA
            cacheLogin = await axios.get('/offlinetoken');
            let value = JSON.parse(cacheLogin);
            if (value) {
              const loginDetails = JSON.parse(value.data);
              if (loginDetails) {
                setUsername(loginDetails.username);
                setDisplayUsername(handlePhoneNumberFormatting(loginDetails.username));
                setPassword(loginDetails.password);
                setConfirmPassword(loginDetails.password);
                //set localStorage here
                //Check if local storage has login info or not
                localStorage.setItem("login", cacheLogin);
                localStorage.setItem("otpVerified", "true");
              }
            }
          }
          if (cacheLogin == null) {
            removeLogin();
          }
          if (cacheLogin == "null") {
            removeLogin();
          }
          // if(cacheOtp == null){
          //   removeLogin();
          // }
          // if(cacheOtp == "null"){
          //   removeLogin();
          // }
          if (localStorage.getItem("login") && localStorage.getItem("otpVerified")) {
            return navigate("/bp1");
          }
        }
      } catch (err) {
        console.log("ERROR:: ", err);
      }
    })();

    if (localStorage.getItem("login")) {
      const loginDetails = JSON.parse(localStorage.getItem("login"));
      setUsername(loginDetails.username);
      setDisplayUsername(handlePhoneNumberFormatting(loginDetails.username));
      setPassword(loginDetails.password);
      setConfirmPassword(loginDetails.password);
    }

    //Handle case when the localstorage doesnt have values
    if (localStorage.getItem("login") === null) {
      localStorage.removeItem("login");
    }
    if (localStorage.getItem("login") === "null") {
      localStorage.removeItem("login");
    }
    if (localStorage.getItem("otpVerified") === null) {
      localStorage.removeItem("otpVerified");
    }
    if (localStorage.getItem("otpVerified") === "null") {
      localStorage.removeItem("otpVerified");
    }

    if (localStorage.getItem("login") && localStorage.getItem("otpVerified")) {
      return navigate("/bp1");
    }
  }, []);

  const handleLogin = async () => {
    if (
      !username ||
      !password ||
      !confirmPassword ||
      password.length !== 9 ||
      confirmPassword.length !== 9
    ) {
      return LoginSwal.fire({
        title: 'Error!',
        text: 'Please enter all the fields',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red'
      });
      //alert("Please enter all the fields");
    }

    try {
      setIsLoginBtnLoading(true);
      if (password !== confirmPassword)
        throw new Error("Authorization Code do not match");
      const newUsername = username
        .replace(" ", "")
        .replace("(", "")
        .replace(")", "")
        .replace("-", "");

      const resOtp = await axios({
        method: "GET",
        url: `https://hub.healthscanner.app/service/generateOTP?phoneNumber=${userCountry && userCountry === "IN" ? 91 : 1
          }${newUsername}`,
      });

      localStorage.setItem(
        "login",
        JSON.stringify({
          username: newUsername,
          password,
          id: params.id || "",
          countryCode: userCountry === "IN" ? 91 : 1,
        })
      );

      //set cache here too
      saveLogin(JSON.stringify({
        username: newUsername,
        password,
        id: params.id || "",
        countryCode: userCountry === "IN" ? 91 : 1,
      }));
      let loginVal = JSON.stringify({
        username: newUsername,
        password,
        id: params.id || "",
        countryCode: userCountry === "IN" ? 91 : 1,
      });

      // setItem('test', 'value').then(function () {
      //   return getItem('test');
      // }).then(function (value) {
      //   // we got our value
      //   console.log("value set");
      // }).catch(function (err) {
      //   // we got an error
      //   console.log("error set");
      // });

      // sendMessage({command: 'keys'})
      // .then(function(data) {
      //   console.log(data);
      // }).catch({}); // If the promise reje

      navigate("/otp-verify");
    } catch (err) {
      console.log("ERROR:: ", err);
      toast({
        title: `${err.message || "Error"}`,
        status: "error",
        duration: 5000,
      });
    }
    setIsLoginBtnLoading(false);
  };

  const handlePhoneNumberFormatting = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, "");

    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneNumberChange = (value) => {
    const formattedNo = handlePhoneNumberFormatting(value);
    setDisplayUsername(formattedNo);
    setUsername(value);
  };

  const forgotPassword = () => {
    if (username) {
      let verify_user = username.replace(/[^0-9]/g, '').length;
      if (verify_user == 10) {
        return LoginSwal.fire({
          title: 'Forgot Authorization Code',
          html: '<span style="font-size: 12pt;">You have forgotten your authorization code for phone number - ' + username + '. We will send you a text message on your phone with your authorization code. <br/> You will NOT need to re-enter your authorization code when you next use the app, as long as you have bookmarked it to your home page. So, every five days, you will be able to immediately initiate a new blood pressure measurement by activating that bookmark.</span>',
          icon: 'info',
          confirmButtonText: 'OK',
          confirmButtonColor: 'red',
          showCloseButton: true,
          showCancelButton: true,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          //Call api here
          if (result.isConfirmed) {
            try {
              const newUsername = username
                .replace(" ", "")
                .replace("(", "")
                .replace(")", "")
                .replace("-", "");

              const resOtp = axios({
                method: "GET",
                url: `https://hub.healthscanner.app/service/sendPassword?phoneNumber=${userCountry && userCountry === "IN" ? 91 : 1
                  }${newUsername}`,
              }).then((data) => {
                if (data && (data.status == 200)) {
                  Swal.fire({ title: 'Text Message sent', text: '', icon: 'success', confirmButtonColor: 'red' });
                } else {
                  toast({
                    title: "Unable to send at this time",
                    status: "error",
                    duration: 5000,
                  });
                }
              });
            } catch (err) {
              console.log("ERROR:: ", err);
              toast({
                title: `${err.message || "Unable to send at this time"}`,
                status: "error",
                duration: 5000,
              });
            }
          }
        });
      } else {
        return LoginSwal.fire({
          title: 'Error!',
          text: 'The entered cellphone number is not complete or invalid',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: 'red'
        });
      }
    } else {
      return LoginSwal.fire({
        title: 'Error!',
        text: 'Please enter the phone number',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red'
      });
    }
  }

  const termsSelected = (e) => {
    if (e === true) {
      setTocItem(true);
      setTocModalIsOpen(true);
      if (privacyItem === true) {
        setBtnDisabled(false);
      }
    } else {
      setTocItem(false);
      setTocModalIsOpen(false);
      setBtnDisabled(true);
    }
  }

  const privacySelected = (e) => {
    if (e === true) {
      setPrivacyItem(true);
      setPpModalIsOpen(true);
      if (tocItem === true) {
        setBtnDisabled(false);
      }
    } else {
      setPrivacyItem(false);
      setPpModalIsOpen(false);
      setBtnDisabled(true);
    }
  }

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="100%"
        h={{ vxs: "24vh", xxs: "23vh", xs: "23vh" }}
        bgImage={biInvert}
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        paddingY="2rem"
      // overflowY="scroll"
      >
        <Image src={topImg} />
      </Flex>
      <VStack
        h={{ vxs: "81vh", xxs: "82vh", xs: "86vh" }}
        paddingX={{ vxs: "1.5rem", xxxs: "2rem", xs: "2rem" }}
        paddingY="2rem"
        // pl="4"
        // pr="4"
        // pt="2"
        // pb="1"
        bgImage={biBg}
        bgPos="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        spacing={{ vxs: 4, xxxs: 4, xs: 6 }}
      >
        <Heading
          textAlign="center"
          fontSize={{ vxs: 17, xxxs: 20, xxs: 22, xs: 24 }}
          color="red"
        >
          One-Time Registration
        </Heading>
        <VStack
          w="100%"
          px="2"
          spacing={{ vxs: 2, xxs: 2, xs: 4 }}
          style={{ marginTop: "8px !important" }}
        >
          <FormControl>
            <FormLabel
              mb="1"
              fontSize={{ vxs: 13, xxxs: 14, xs: 18 }}
              color="white"
            >
              User's cellphone number
            </FormLabel>
            <PhoneInput
              placeholder="Enter phone number"
              border="none"
              maxLength={14}
              color="white"
              value={phoneInput}
              onChange={setPhoneInput}
              fontSize={{ vxs: 13, xxxs: 14, xs: 18 }}
              _focusVisible={{ outline: "none" }}
              // placeholder={"(_ _ _ ) _ _ _ -_ _ _ _"}
              defaultCountry="US"
              country="US"
              className="customPhoneBtn"
              countries={["US", "CA", "IN"]}
            />
            {/* <Input
              type="text"
              maxLength={14}
              border="none"
              color="white"
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              value={displayUsername}
              fontSize={{ vxs: 13, xxxs: 14, xs: 18 }}
              _focusVisible={{ outline: "none" }}
              placeholder="(_ _ _ ) _ _ _ -_ _ _ _"
            /> */}
          </FormControl>
          <FormControl>
            <Flex alignItems="center">
              <FormLabel
                mb="1"
                fontSize={{ vxs: 13, xxxs: 14, xs: 18 }}
                color="white"
              >
                Authorization Code
              </FormLabel>
              {/* <Icon
                as={!showPassword ? AiOutlineEyeInvisible : AiOutlineEye}
                w={{ vxs: 3.5, xxxs: 5 }}
                h={{ vxs: 3.5, xxxs: 5 }}
                ml={-1}
                mb={1}
                color="white"
                onClick={() => setShowPassword((prev) => !prev)}
              /> */}
            </Flex>
            <HStack>
              <Text
                color="gray.400"
                textAlign={{ vxs: "center", xs: "center" }}
                fontSize={{ vxs: 10, xxxs: 12, xs: 16 }}
                fontStyle="italic"
                mt={{ vxs: "-1 !important", xs: "2 !important" }}
                mb={{ vxs: "4 !important", xs: "4 !important" }}
              >
                (From scratch-off authorization card or delivered
                electronically)
              </Text>
              {/* <Icon
                as={AiOutlineInfoCircle}
                color="gray.400"
                fontSize="20"
                onClick={onOpen}
              /> */}
            </HStack>
            <HStack justifyContent="center" mb={4} px={2}>
              <PinInput
                mask={!showPassword}
                size={{ vxs: "xs", xxs: "sm", xs: "lg" }}
                onChange={(e) => setPassword(e)}
                type="alphanumeric"
                value={password}
              >
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
              </PinInput>
            </HStack>
            <Flex alignItems="center">
              <FormLabel
                mb={{ vxs: "2", xs: "4" }}
                fontSize={{ vxs: 13, xxxs: 14, xs: 18 }}
                color="white"
              >
                Repeat Authorization Code
              </FormLabel>
              {/* <Icon
                as={!showConfirmPassword ? AiOutlineEyeInvisible : AiOutlineEye}
                w={{ vxs: 3.5, xxxs: 5 }}
                h={{ vxs: 3.5, xxxs: 5 }}
                ml={-1}
                mb={{ vxs: 2, xxxs: 4 }}
                color="white"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              /> */}
            </Flex>
            <HStack justifyContent="center" mb={{ vxs: 2, xs: 4 }} px={2}>
              <PinInput
                mask={!showConfirmPassword}
                size={{ vxs: "xs", xxs: "sm", xs: "lg" }}
                onChange={(e) => setConfirmPassword(e)}
                type="alphanumeric"
                value={confirmPassword}
              >
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
                <PinInputField color="white" fontFamily="Roboto Mono" />
              </PinInput>
            </HStack>
          </FormControl>
          <Flex style={{
            color: "white", marginTop: "-1px", textAlign: "left", width: "100%", marginBottom: "8px"
            , fontStyle: "italic", fontSize: "0.9rem", color: "#a0aec0"
          }}>
            <span id="forgot" onClick={forgotPassword} style={{ fontWeight: "bolder", color: "red", cursor: "pointer" }}>Tap Here</span>&nbsp;if you have forgotton your Authorization Code
          </Flex>
        </VStack>
        <Text
          color="white"
          fontSize={{ vxs: 11, xxxs: 12, xxs: 13, xs: 16, sm: 18 }}
          textAlign="justify"
        >
          In order to protect your privacy, and the security of your smartphone,
          we will text you a six-digit code which must be entered on the next
          screen in order to complete this one-time registration process. Please
          tap the red button below to proceed and you will then have ten minutes
          to enter this security code. Thank you.
        </Text>
        <Flex style={{
          color: "white", marginTop: "1px", textAlign: "center", width: "100%", marginBottom: "8px"
          , fontStyle: "italic", fontSize: "0.9rem", color: "#bfdff2"
        }}>
          This App supports iOS 13+ OR Android 8+. Please ensure that the Operating System of your device is up to date.
        </Flex>

        {/* <VStack color="white" style={{marginTop:"-5px"}}>
        <Box style={{fontSize: "0.9rem"}}>I hereby acknowledge my acceptance of these -<br/>
          <Checkbox colorScheme='green' isChecked={tocItem} onChange={(e) => termsSelected(e.target.checked)} style={{color: "white", fontSize: "0.9rem"}}>
            <span style={{fontSize: "0.9rem"}}>Terms of Use</span>&nbsp;&nbsp;&nbsp;
          </Checkbox>
          <Checkbox colorScheme='green' isChecked={privacyItem} onChange={(e) => privacySelected(e.target.checked)} style={{color: "white", fontSize: "0.9rem"}}>
            <span style={{fontSize: "0.9rem"}}>Privacy Policy</span>
          </Checkbox>
          </Box>
        </VStack> */}

        <VStack color="white" style={{ marginTop: "5px", width: "100%", paddingBottom: "10px" }}>
          <Box style={{ fontSize: "0.9rem", marginBottom: "16px" }}><u>To proceed with registration you must first click-on, review and acknowledge the two notices identified below.</u><br />
            <Flex direction="row" justifyContent="center" style={{ marginTop: "8px" }}>
              <div onClick={(e) => termsSelected(true)} style={{ color: tocItem ? "lightgreen" : "red", fontStyle: "italic", fontWeight: "bold", cursor: "pointer" }}>
                <u>Terms of Use</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div onClick={(e) => privacySelected(true)} style={{ color: privacyItem ? "lightgreen" : "red", fontStyle: "italic", fontWeight: "bold", cursor: "pointer" }}>
                <u>Privacy Policy</u>
              </div>
            </Flex>
          </Box>

          <Box
            as="button"
            bg={btnDisabled ? "gray" : "red"}
            color="white"
            mt={{
              vxs: "1 !important",
              xxs: "1 !important",
              xs: "auto !important",
            }}
            mb={{ xs: "5 !important" }}
            w="100%"
            fontSize={{ vxs: 14, xs: 18 }}
            _hover={{ bg: btnDisabled ? "gray" : "red" }}
            _focus={{ bg: btnDisabled ? "gray" : "red" }}
            _active={{ bg: btnDisabled ? "gray" : "red" }}
            onClick={handleLogin}
            isLoading={isLoginBtnLoading}
            height="5vh"
            disabled={btnDisabled}
          >
            NEXT - Login Verification
          </Box>

        </VStack>




        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent mx="5" rounded="md">
            <ModalHeader
              bg="red.600"
              color="white"
              roundedTopLeft="md"
              roundedTopRight="md"
            >
              Authorization Code Reference
            </ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody>
              <HStack alignItems="center" justifyContent="space-between">
                <Text color="black">I J L O</Text>
                <Text color="black">i j l o</Text>
                <Text color="black">0 1</Text>
              </HStack>
              <HStack alignItems="center" justifyContent="space-between">
                <Text color="red.400" fontFamily="Roboto Mono">
                  I J L O
                </Text>
                <Text color="red.400" fontFamily="Roboto Mono">
                  i j l o
                </Text>
                <Text color="red.400" fontFamily="Roboto Mono">
                  0 1
                </Text>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>

        {
          tocModalIsOpen && <TermsOfUse isTocModalOpen={tocModalIsOpen} showToc={setTocModalIsOpen} />
        }

        {
          ppModalIsOpen && <PrivacyPolicy isTocModalOpen={ppModalIsOpen} showToc={setPpModalIsOpen} />
        }

      </VStack>
    </>
  );
};

export default Login;
