import { Button, Image, Link, Text, VStack } from "@chakra-ui/react";

import PageMid from "../components/PageMid";
import PageTopHUB from "../components/PageTopHUB";
import WellnessLogo from "../assets/wellness_logo.png";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

const Finish = () => {
  const navigate = useNavigate();

  const bottomRef = useRef();

  const handleNext = () => {
    navigate("/finish");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTopHUB heading="What This Means" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <VStack
          color="white"
          fontSize={{ vxs: 11.5, xxxs: 15, xxs: 14, xs: 16 }}
          spacing={{ vxs: 2, xxxs: 3.5, xs: 6 }}
        >
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            An alarming number of drivers suffer from type2 diabetes and that
            condition can negatively effect the length of their medical card.
          </Text>
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            The good news, though, is that you can find solutions right here. If
            you're confident that you can manage the condition yourself, we have
            self-care tips that can make a huge difference. If you need health
            coaching to succeed, we have affordable solutions that will come to
            you on your phone, wherever you may be.
          </Text>
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            <i>Health in Transportation</i> has years of experience of helping
            CDL drivers through their DOT medical and it is now working with{" "}
            <i>MedWell</i> to provide personalized programs to users of this
            app. Great progress can be achieved with short, twice-weekly
            programs of remote coaching. For drivers who feel the need for that
            level of help, clicking the link below will allow you to book time
            with your own, personal health coach. <br />
            <Link
              href="https://www.mymedwellness.com/driverassist.html"
              color="blue.400"
              borderBottom="1px solid"
              isExternal
            >
              https://www.mymedwellness.com/driverassist.html
            </Link>
          </Text>
          <Text
            textAlign="justify"
            mb={{ vxs: "1 !important", xxs: "3 !important" }}
          >
            <i>There's Way Too Much To Lose</i>. Running your personal engine
            with elevated BP, BMI and sugar levels can put you at risk. The team
            at <i>MedWell</i> wants to help protect your livelihood and get you
            home safe to see your family.
          </Text>
          <Image
            src={WellnessLogo}
            w={{ vxs: "200px", xxxs: "250px", sm: "300px" }}
            mt={{
              vxs: "2 !important",
              xxxs: "-1 !important",
              sm: "4 !important",
            }}
          />
        </VStack>
        <Button
          ref={bottomRef}
          mt={{
            vxs: "4 !important",
            xxxs: "auto !important",
            xxs: "4 !important",
            sm: "auto !important",
          }}
          mb="1 !important"
          w="100%"
          bg="red"
          color="white"
          py={{ vxs: "2", xxxs: "0", xxs: "2", sm: "2" }}
          fontSize={{ vxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={handleNext}
        >
          NEXT - Sleep Apnea
        </Button>
      </PageMid>
    </>
  );
};

export default Finish;
