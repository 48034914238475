import { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  Flex,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ListItem,
  UnorderedList,
  Checkbox, CheckboxGroup,
  useDisclosure,
} from "@chakra-ui/react";

const TermsOfUse = ({ showToc, isTocModalOpen }) => {
  // const [isOpen, setIsOpen] = React.useState(false);

  const initialRef = useRef(null)

  useEffect(() => {
    // const ele = document.querySelector("#modalid");
    // if (ele) ele.scrollIntoView();
    window.scrollTo(0, 0);
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checkedItem, setCheckedItem] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const closeTocModal = () => {
    showToc(false)
  }

  const userSelected = (e) => {
    if (e == true) {
      setCheckedItem(true);
      setBtnDisabled(false);
    } else {
      setCheckedItem(false);
      setBtnDisabled(true);
    }
  }

  return (
    <>
      <Modal isOpen={isTocModalOpen} onClose={onClose} scrollBehavior="inside" initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent mx="5" rounded="md" minWidth="fit-content"
          height="fit-content" top>
          <ModalHeader
            bg="red.600"
            color="white"
            roundedTopLeft="md"
            roundedTopRight="md"
            textAlign="center"
            fontSize="25px"
            fontWeight='600'
          >
            Terms of Use
          </ModalHeader>
          <ModalBody
            paddingX="5rem">
            <VStack alignItems="left" align="justify" justifyContent="space-between" className="p-10">
              <Box as="button" ref={initialRef} placeholder='' />
              <Text color="black" as='i' align="center">
                Please note these Terms of Use for app usage. Scroll down for acceptance acknowledgement.</Text><br />
              <Heading color="black" as="b" align="center">CDL Health Scanner LLC</Heading><br />
              <Text color="black" as='u' fontSize='xl' align="center">TERMS OF USE</Text><br />

              <Text color="black" as='i' align="center"><u>Last Updated: December 18, 2023</u></Text><br />
              <Text color="black" as="b" align="center">NOTICES AND DISCLAIMERS</Text><br />
              <Text color="black" align="justify">
                While The CDL Health Scanner App is only available through the HDOL Marketplace, CDL
                Health Scanner is responsible for the function of, information generated by the use of and the
                privacy practices of CDL Health Scanner and the App. In rendering the Health Scanner App
                available through its Marketplace, HDOL assumes no liability in connection with the Health
                Scanner App.
              </Text><br />
              <Text color="black" align="justify">
                The CDL Health Scanner App is intended for informational purposes only and does not
                diagnose or establish a treatment plan for any health condition. Use of the CDL Health
                Scanner Application is not a substitute for the advice of a medical professional. If you have
                concerns or questions about your health, promptly seek out the advice of a qualified medical
                professional.
              </Text><br />
              <Text color="black" align="justify">
                You must be 18 years of age or older to use the CDL Health Scanner App. Use of the App by minors
                under the age of 18 is strictly prohibited. We do not knowingly or intentionally collect
                Personal Data from any person under the age of 18.
              </Text>
              <Text color="black" as="b" align="center">Acceptance of the Terms of Use</Text><br />
              <Text color="black" align="justify">
                These Terms of Use are applicable to your download and use of the CDL Health Scanner App,
                provided by CDL Health Scanner, LLC ( “we,” “us”, “our” or “CDL”). These Terms of Use (“Terms
                of Use” or “Terms”) are entered into by and between You and CDL Health Scanner, LLC
                (&quot;Company&quot; or CDL). The following terms govern your access to and use of
                https://hub.healthscanner.app hub.healthscanner.app (the “Website”), including any
                content, functionality, and services offered on or through the Website and through the CDL
                Health Scanner application (the “App”).
              </Text><br />
              <Text color="black" align="justify">
                Please read the Terms of Use carefully before you start to use the Website or App. By clicking
                to accept or agree to the Terms of Use when this option is made available to you, you accept
                and agree to be bound and abide by these Terms of Use and our Privacy Policy, found at
                https://hub.healthscanner.app/privacynotice. If you do not want to agree to these Terms of
                Use or the Privacy Policy, you must not access or use the Website or App.
              </Text><br />
              <Text color="black" as='b'>Changes to the Terms of Use</Text>
              <Text color="black" align="justify">
                We may revise and update these Terms of Use from time to time in our sole discretion. All
                changes are effective immediately when we post them and apply to all access to and use of the
                Website and App thereafter. These Terms of Use, including updates, can be accessed at any
                time at https://hub.healthscanner.app/termsofuse.
              </Text>
              <Text color="black" align="justify">
                Your continued use of the Website or App following the posting of revised Terms of Use means
                that you accept and agree to the changes. You are expected to check this page frequently so
                that you are aware of any changes, as they are binding on you.
              </Text>

              <Text color="black" as="b">Accessing the Website and/or App and Account Security</Text>
              <Text color="black" align="justify">
                We reserve the right to withdraw or amend this Website and/or the App, and any service or
                material we provide on the Website or through the App, in our sole discretion without notice.
                We will not be liable if for any reason all or any part of the Website or App is unavailable at any
                time or for any period. From time to time, we may restrict access to some parts of the Website
                or App, or the entire Website or App, to users, including registered users.
                You are responsible for both:
              </Text>
              <UnorderedList px="10">
                <ListItem>Making all arrangements necessary for you to have access to the Website and the App.</ListItem>
                <ListItem>Ensuring that you are aware of these Terms of Use and comply with them.</ListItem>
              </UnorderedList><br />

              <Text color="black" align="justify">
                To access the Website or App and the resources they offer, you may be asked to provide certain
                registration details or other information. It is a condition of your use of the Website and the
                App that all the information you provide is correct, current, and complete. You agree that all
                information you provide to register on the Website or the App, including, but not limited to, the
                use of any interactive features, is governed by our Privacy Policy at
                https://hub.healthscanner.app/privacynotice, and you consent to all actions we take with
                respect to your information consistent with our Privacy Policy.
              </Text><br />

              <Text color="black" align="justify">
                If you choose a username and password, or any other piece of information as part of our
                security procedures, you must treat such information as confidential, and you must not disclose
                it to any other person or entity. You also acknowledge that your account is personal to you and
                agree not to provide any other person with access to this Website or App using your username,
                password, or other security information. You agree to notify us immediately of any
                unauthorized access to or use of your username or password or any other breach of security.
                You also agree to ensure that you exit your account at the end of each session. You should use
                caution when accessing your account from a public place so that others are not able to view
                your personal information, including your password and information generated by the App.
              </Text><br />

              <Text color="black" align="justify">
                We have the right to disable any username, password, or other identifier at any time if, in our
                opinion, you have violated any provision of these Terms of Use.
              </Text><br />

              <Text color="black" as="b">Intellectual Property Rights</Text>
              <Text color="black" align="justify">
                The Website and the App, and their entire contents, features, and functionality (including, but
                not limited to, all information, software, text, displays, images, video, and audio, and the
                design, selection, and arrangement thereof) are owned by the Us, our licensors, or other
                providers of such material and are protected by United States and international copyright,
                trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                These Terms of Use permit you to use the Website and App as authorized by their specific
                terms and these Terms. You must not reproduce, distribute, modify, create derivative works of,
                publicly display, publicly perform, republish, download, store, or transmit any of the material
                on our Website or on the APP, except to take a photograph of certain data delivered by the App
                for your personal use, or to view, print and download other information available on the
                Website for your personal use.
              </Text><br />

              <Text color="black" align="justify">
                You must not access or use for any commercial purposes any part of the Website or any
                services or materials available through the Website or the App.
              </Text><br />

              <Text color="black" align="justify">
                If you provide any other person with access to any part of the Website or App in breach of the
                Terms of Use, your right to use the Website and App will stop immediately. No right, title, or
                interest in or to the Website or App or any content available on either, with the exception of
                the health scan information which is personal to you, is transferred to you, and all rights not
                expressly granted are reserved by us. Any use of the Website or App not expressly permitted by
                these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and
                other laws.
              </Text><br />

              <Text color="black" as="b">Trademarks</Text>
              <Text color="black" align="justify">Our name, the name of the APP, and all related names, logos, product and service names,
                designs, and slogans are our trademarks or those of our affiliates or licensors. You must not use
                such marks without our prior written permission.</Text><br />

              <Text color="black" as="b">Prohibited Uses</Text>
              <Text color="black" align="justify">You may use the Website and App only for lawful purposes and in accordance with these Terms
                of Use. You agree not to use or access the Website or App:</Text><br />
              <UnorderedList px="10">
                <ListItem>If you are younger than 18 years of age.</ListItem>
                <ListItem>For purposes of obtaining a medical diagnosis or treatment plan, which can only be
                  provided by a qualified medical professional.</ListItem>
                <ListItem>In any way that violates any applicable federal, state, local, or international law or
                  regulation (including, without limitation, any laws regarding the export of data or
                  software to and from the US or other countries).</ListItem>
                <ListItem>In any manner that could disable, overburden, damage, or impair the site or interfere
                  with any other party&#39;s use of the Website, including their ability to engage in real time
                  activities through the Website.</ListItem>
                <ListItem>Through the use of any robot, spider, or other automatic device, process, or means for
                  any purpose, including monitoring or copying any of the material on the Website.</ListItem>
                <ListItem>Through the use of any manual process to monitor or copy any of the material on the
                  Website, or for any other purpose not expressly authorized in these Terms of Use,
                  without our prior written consent.</ListItem>
                <ListItem>To use any device, software, or routine that interferes with the proper working of the
                  Website.</ListItem>
                <ListItem>To introduce any viruses, Trojan horses, worms, logic bombs, or other material that is
                  malicious or technologically harmful.</ListItem>
                <ListItem>To attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
                  of the Website, the server on which the Website is stored, or any server, computer, or
                  database connected to the Website.</ListItem>
                <ListItem>To attack the Website via a denial-of-service attack or a distributed denial-of-service
                  attack.</ListItem>
                <ListItem>To attempt to interfere with the proper working of the Website in any other way or by
                  any other means.</ListItem>

              </UnorderedList><br />

              <Text color="black" as="b">Reliance on Information Posted</Text>
              <Text color="black" align="justify">
                The information presented on or through the Website and the App is made available solely for
                general information purposes. We do not warrant the accuracy, completeness, or usefulness
                of this information. Any reliance you place on such information is strictly at your own risk.
                We disclaim all liability and responsibility arising from any reliance placed on such materials
                by you.
              </Text>
              <Text color="black" align="justify">
                This Website may include content provided by third parties. All statements and/or opinions
                expressed in these materials, and all articles and responses to questions and other content,
                other than the content provided by us, are solely the opinions and the responsibility of the
                person or entity providing those materials. These materials do not necessarily reflect the
                opinion of the Company. We are not responsible or liable to you or any third party for the
                content or accuracy of any materials provided by any third party.
              </Text><br />

              <Text color="black" as="b">Changes to the Website or App</Text>
              <Text color="black" align="justify">
                We may update the content displayed on this Website or App from time to time. You should
                check regularly for such updates.
              </Text><br />


              <Text color="black" as="b">Information About You and Your Visits to the Website</Text>
              <Text color="black" align="justify">
                All information we collect on this Website or App about you is subject to our Privacy Policy at
                https://hub.healthscanner.app/privacynotice. By using the Website or App, you give
                consent for us to process and use your information in accordance with the terms of our Privacy
                Policy.
              </Text><br />

              <Text color="black" as="b">Links on the Website</Text>
              <Text color="black" align="justify">
                If the Website contains links to other sites and resources provided by third parties, these links
                are provided for your convenience only. This includes links contained in advertisements,
                including banner advertisements and sponsored links. We have no control over the contents of
                those sites or resources and accept no responsibility for them or for any loss or damage that
                may arise from your use of them. If you decide to access any of the third-party websites linked
                to this Website, you do so entirely at your own risk and subject to the terms and conditions of
                use for such websites.
              </Text><br />


              <Text color="black" as="b">Geographic Restrictions - CDL</Text>
              <Text color="black" align="justify">
                We are based in the State of Utah in the United States. We provide the Website and App for
                use only by persons located in the United States. We make no claims that the Website or App
                or any of their content is accessible or appropriate outside of the United States. Access to the
                Website and App may not be legal by certain persons or in certain countries. If you access the
                Website or App from outside the United States, you do so on your own initiative and are
                responsible for compliance with local laws.
              </Text><br />

              <Text color="black" as="b">Disclaimer of Warranties</Text>
              <Text color="black" align="justify">
                You understand that we cannot and do not guarantee or warrant that the Website and App will
                be free of viruses or other destructive code. You are responsible for implementing sufficient
                procedures and checkpoints to satisfy your particular requirements for anti-virus protection
                and for maintaining a means external to our site for any reconstruction of any lost data. TO THE
                FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE
                WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, YOUR
                DOWNLOADING OF ANY MATERIAL POSTED ON IT OR ANY WEBSITE LINKED TO IT, OR YOUR
                USE OF THE APP.
              </Text><br />
              <Text color="black" align="justify">
                YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE WEBSITE, AND YOUR USE OF THE APP ARE AT YOUR OWN RISK. THE WEBSITE, ITS
                CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, AND THE APP ARE
                PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY
                KIND, EITHER EXPRESS OR IMPLIED. NEITHER WE NOR ANY PERSON ASSOCIATED WITH US
                MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
                SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE OR APP.
                WITHOUT LIMITING THE FOREGOING, NEITHER WE NOR ANYONE ASSOCIATED WITH US
                REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE WEBSITE, OR THE APP WILL BE ACCURATE, RELIABLE, ERROR-FREE,
                OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER
                THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
                THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE, OR THE
                APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </Text><br />
              <Text color="black" align="justify">
                TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-
                INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
              </Text><br />
              <Text color="black" align="justify">
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
                LIMITED UNDER APPLICABLE LAW.
              </Text><br />

              <Text color="black" as="b">Limitation on Liability</Text>
              <Text color="black" align="justify">
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL WE, OUR AFFILIATES, OR OUR
                LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE
                FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
                WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE OR APP, OR ANY CONTENT ON THE
                WEBSITE OR SUCH OTHER WEBSITES LINKED THEREON, INCLUDING ANY DIRECT, INDIRECT,
                SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS
                OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
                LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
              </Text><br />

              <Text color="black" as="b">Indemnification</Text>
              <Text color="black" align="justify">
                You agree to defend, indemnify, and hold us, our affiliates, licensors, and service providers, and
                our and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
                successors, and assigns harmless from and against any claims, liabilities, damages, judgments,
                awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out of or
                relating to: (i) your violation of these Terms of Use; (ii) your use of the Website, including, but
                not limited to, any Website content, services, and products; (iii) your use of the App other than
                as expressly authorized in these Terms of Use; or (iv) your use of any information obtained from
                the Website or the App.
              </Text><br />

              <Text color="black" as="b">Governing Law and Jurisdiction</Text>
              <Text color="black" align="justify">
                All matters relating to the Website, App and these Terms of Use, and any dispute or claim
                arising therefrom or related thereto (in each case, including non-contractual disputes or
                claims), shall be governed by and construed in accordance with the internal laws of the State of
                Utah without giving effect to any choice or conflict of law provision or rule (whether of the
                State of Utah or any other jurisdiction).
              </Text><br />
              <Text color="black" align="justify">
                Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the
                Website or App shall be instituted exclusively in the federal courts of the United States or the
                courts of the State of Utah, in each case located in Salt Lake City and County of Salt Lake,
                although we retain the right to bring any suit, action, or proceeding against you for breach of
                these Terms of Use in your country of residence or any other relevant country. You waive any
                and all objections to the exercise of jurisdiction over you by such courts and to venue in such
                courts.
              </Text><br />

              <Text color="black" as="b">Arbitration</Text>
              <Text color="black" align="justify">
                At our sole discretion, we may require You to submit any disputes arising from these Terms of
                Use or your use of the Website or APP, including disputes arising from or concerning their
                interpretation, violation, invalidity, non-performance, or termination, to final and binding
                arbitration under the Rules of Arbitration of the American Arbitration Association applying Utah
                law.
              </Text><br />

              <Text color="black" as="b">Waiver and Severability</Text>
              <Text color="black" align="justify">
                No waiver by us of any term or condition set out in these Terms of Use shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term or
                condition, and any failure by us to assert a right or provision under these Terms of Use shall not
                constitute a waiver of such right or provision.
              </Text><br />

              <Text color="black" align="justify">
                If any provision of these Terms of Use is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the remaining provisions of the Terms of
                Use will continue in full force and effect.
              </Text><br />

              <Text color="black" as="b">Contact Us</Text><Text color="black">The app is owned and operated by CDL Health Scanner LLC. If you have any questions regarding these Terms, email us at <a href="support@healthintransportation.com">support@healthintransportation.com</a> or contact us by mail at Unit #1, 5130 S. Fort Apache Road, Ste 215-231, Las Vegas, NV 89148.</Text>

              <Checkbox colorScheme='green' isChecked={checkedItem} onChange={(e) => userSelected(e.target.checked)}>
                I hereby acknowledge my acceptance of these <b>Terms of Use</b>
              </Checkbox>

              <Flex width="100%" justifyContent="center">
                <Box
                  as='button'
                  bg={btnDisabled ? "gray" : "red"}
                  color="white"
                  mt={{
                    vxs: "1 !important",
                    xxs: "1 !important",
                    xs: "auto !important",
                  }}
                  mb={{ xs: "5 !important" }}
                  w="20%"
                  fontSize={{ vxs: 14, xs: 18 }}
                  _hover={{ bg: btnDisabled ? "gray" : "red" }}
                  _focus={{ bg: btnDisabled ? "gray" : "red" }}
                  _active={{ bg: btnDisabled ? "gray" : "red" }}
                  onClick={closeTocModal}
                  height="40px"
                  disabled={btnDisabled}
                >
                  Close
                </Box>
              </Flex>

            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

};

export default TermsOfUse;
