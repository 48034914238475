import { Image, Flex, Heading, Icon } from "@chakra-ui/react";
import { useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router";
import { removeLogin } from "../components/cachestorage";

import bgInvert from "../assets/bi_invert.png";
import topImg from "../assets/bi-top-new-logo-hub.png";

const PageTopHUB = ({ heading }) => {
  let defferedPrompt;
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      defferedPrompt = e;
    });
  }, []);

  const installBtn = async () => {
    try {
      defferedPrompt.prompt();
      const { outcome } = await defferedPrompt.userChoice;
      if (outcome === "accepted") {
        defferedPrompt = null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const logoutBtn = async () => {
    try {
      removeLogin();
      localStorage.removeItem("login");
      localStorage.removeItem("ageGender");
      localStorage.removeItem("otpVerified");
      localStorage.removeItem("promoCode");
      localStorage.removeItem("chakra-ui-color-mode");
      return navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      h={{ vxs: "26vh", xxs: "25vh", xs: "25vh" }}
      bgImage={bgInvert}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      paddingY="4rem"
      flexDirection="column"
    // overflowY="scroll"
    >
      <Image src={topImg} />
      <Heading color="red" fontSize={{ vxs: 20, xxxs: 24 }} letterSpacing={0.5}>
        {heading}
      </Heading>
      {/* navigator.platform !== "iPhone" &&
        heading === "The CDL Health Scanner" && (
          <Icon
            as={AiFillStar}
            m="1"
            mb={{ vxs: "6", xxs: "8" }}
            fill="#DAA520"
            onClick={installBtn}
            w={{ xxs: 7, sm: 8 }}
            h={{ xxs: 7, sm: 8 }}
          />
          19,18,14
        ) */}
    </Flex>
  );
};

export default PageTopHUB;
